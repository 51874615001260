/**
 * Panther is a Cloud-Native SIEM for the Modern Security Team.
 * Copyright (C) 2020 Panther Labs Inc
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import React from 'react';
import { Box, FadeIn } from 'pouncejs';
import TabsSkeleton from './TabsSkeleton';

const LogAnalysisOverviewPageSkeleton: React.FC = () => {
  return (
    <Box as="article" mb={6}>
      <FadeIn duration={400}>
        <TabsSkeleton tabs={[{ label: 'Real-Time Alerts' }, { label: 'Most Active Rules' }]} />
        <TabsSkeleton
          tabs={[{ label: 'Events by Log Type' }, { label: 'Data Latency by Log Type' }]}
        />
        <TabsSkeleton tabs={[{ label: 'Recent Alerts' }, { label: 'High Severity Alerts' }]} />
      </FadeIn>
    </Box>
  );
};

export default LogAnalysisOverviewPageSkeleton;
